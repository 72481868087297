var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Edit Data Table", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("You can edit the data with "),
        _c("strong", [_vm._v("slot")]),
        _c("code", [_vm._v("edit")]),
        _vm._v(", so you have better flexibility and mastery of what you need"),
      ]),
      _c(
        "vs-table",
        {
          attrs: { data: _vm.users },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      _c(
                        "vs-td",
                        { attrs: { data: tr.email } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tr.email) +
                              "\n                        "
                          ),
                          _c(
                            "template",
                            { slot: "edit" },
                            [
                              _c("vs-input", {
                                staticClass: "inputx",
                                attrs: { placeholder: "Email" },
                                model: {
                                  value: tr.email,
                                  callback: function ($$v) {
                                    _vm.$set(tr, "email", $$v)
                                  },
                                  expression: "tr.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: tr.username } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tr.username) +
                              "\n                        "
                          ),
                          _c(
                            "template",
                            { slot: "edit" },
                            [
                              _c(
                                "vs-select",
                                {
                                  attrs: { label: "Users" },
                                  model: {
                                    value: tr.username,
                                    callback: function ($$v) {
                                      _vm.$set(tr, "username", $$v)
                                    },
                                    expression: "tr.username",
                                  },
                                },
                                _vm._l(_vm.users, function (item, index) {
                                  return _c("vs-select-item", {
                                    key: index,
                                    attrs: {
                                      value: item.name,
                                      text: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: tr.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tr.id) +
                              "\n                        "
                          ),
                          _c(
                            "template",
                            { slot: "edit" },
                            [
                              _c("vs-input-number", {
                                model: {
                                  value: tr.id,
                                  callback: function ($$v) {
                                    _vm.$set(tr, "id", $$v)
                                  },
                                  expression: "tr.id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: tr.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tr.id) +
                              "\n                        "
                          ),
                          _c(
                            "template",
                            { slot: "edit" },
                            [
                              _c("vs-slider", {
                                attrs: { max: 20 },
                                model: {
                                  value: tr.id,
                                  callback: function ($$v) {
                                    _vm.$set(tr, "id", $$v)
                                  },
                                  expression: "tr.id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Email")]),
              _c("vs-th", [_vm._v("Name")]),
              _c("vs-th", [_vm._v("Nro1")]),
              _c("vs-th", [_vm._v("Nro2")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-table :data="users">\n\n    <template slot="thead">\n      <vs-th>Email</vs-th>\n      <vs-th>Name</vs-th>\n      <vs-th>Nro1</vs-th>\n      <vs-th>Nro2</vs-th>\n    </template>\n\n    <template slot-scope="{data}">\n      <vs-tr :key="indextr" v-for="(tr, indextr) in data">\n\n        <vs-td :data="tr.email">\n          ' +
            _vm._s("{{ tr.email }}") +
            '\n          <template slot="edit">\n            <vs-input v-model="tr.email" class="inputx" placeholder="Email" />\n          </template>\n        </vs-td>\n\n        <vs-td :data="tr.username">\n          ' +
            _vm._s("{{ tr.username }}") +
            '\n          <template slot="edit">\n            <vs-select label="Users" v-model="tr.username">\n              <vs-select-item :key="index" :value="item.name" :text="item.name" v-for="(item,index) in users" />\n            </vs-select>\n          </template>\n        </vs-td>\n\n        <vs-td :data="tr.id">\n          ' +
            _vm._s("{{ tr.id }}") +
            '\n          <template slot="edit">\n            <vs-input-number v-model="tr.id" />\n          </template>\n        </vs-td>\n\n        <vs-td :data="tr.id">\n          ' +
            _vm._s("{{ tr.id }}") +
            '\n          <template slot="edit">\n            <vs-slider :max="20" v-model="tr.id" />\n          </template>\n        </vs-td>\n\n      </vs-tr>\n    </template>\n  </vs-table>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      users: [......]\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }