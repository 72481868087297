var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "State", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "There are times when we need to add a state to our tr in the table so we have the property "
        ),
        _c("strong", [_vm._v("state")]),
        _vm._v(" inside the component "),
        _c("code", [_vm._v("vs-tr")]),
      ]),
      _c(
        "vs-table",
        {
          attrs: { data: _vm.users },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    {
                      key: indextr,
                      attrs: {
                        state:
                          indextr == 2 || indextr == 5
                            ? "success"
                            : indextr == 6
                            ? "danger"
                            : null,
                      },
                    },
                    [
                      _c("vs-td", { attrs: { data: data[indextr].email } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].email) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].username } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].name) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].website) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].id) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Email")]),
              _c("vs-th", [_vm._v("Name")]),
              _c("vs-th", [_vm._v("Website")]),
              _c("vs-th", [_vm._v("Nro")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-table :data="users">\n\n    <template slot="thead">\n      <vs-th>Email</vs-th>\n      <vs-th>Name</vs-th>\n      <vs-th>Website</vs-th>\n      <vs-th>Nro</vs-th>\n    </template>\n\n    <template slot-scope="{data}">\n      <vs-tr :state="indextr == 2 || indextr == 5 ? \'success\':indextr == 6 ? \'danger\':null" :key="indextr" v-for="(tr, indextr) in data">\n\n        <vs-td :data="data[indextr].email">\n          ' +
            _vm._s("{{ data[indextr].email }}") +
            '\n        </vs-td>\n\n        <vs-td :data="data[indextr].username">\n          ' +
            _vm._s("{{ data[indextr].name }}") +
            '\n        </vs-td>\n\n        <vs-td :data="data[indextr].id">\n          ' +
            _vm._s("{{ data[indextr].website }}") +
            '\n        </vs-td>\n\n        <vs-td :data="data[indextr].id">\n          ' +
            _vm._s("{{ data[indextr].id }}") +
            "\n        </vs-td>\n      </vs-tr>\n    </template>\n\n  </vs-table>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      users: [......]\n    }\n  },\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }