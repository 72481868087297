var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "ExpandableData", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can add the functionality of expanding a "),
        _c("strong", [_vm._v("tr")]),
        _vm._v(
          " to visualize more data to make a structure of data or more complex functionalities"
        ),
      ]),
      _c("br"),
      _c(
        "vs-table",
        {
          attrs: { data: _vm.users },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr, attrs: { data: tr } },
                    [
                      _c("vs-td", { attrs: { data: tr.email } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(tr.email) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: tr.username } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(tr.name) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: tr.website } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(tr.website) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: tr.id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(tr.id) +
                            "\n                    "
                        ),
                      ]),
                      _c(
                        "template",
                        { staticClass: "expand-user", slot: "expand" },
                        [
                          _c(
                            "div",
                            { staticClass: "con-expand-users w-full" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "con-btns-user flex items-center justify-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "con-userx flex items-center justify-start",
                                    },
                                    [
                                      _c("vs-avatar", {
                                        attrs: {
                                          badge: tr.id,
                                          size: "45px",
                                          src: `https://randomuser.me/api/portraits/women/${tr.id}.jpg`,
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(tr.name))]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("vs-button", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          type: "border",
                                          size: "small",
                                          "icon-pack": "feather",
                                          icon: "icon-phone",
                                        },
                                      }),
                                      _c("vs-button", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          type: "border",
                                          size: "small",
                                          "icon-pack": "feather",
                                          icon: "icon-send",
                                          color: "success",
                                        },
                                      }),
                                      _c("vs-button", {
                                        attrs: {
                                          type: "border",
                                          size: "small",
                                          "icon-pack": "feather",
                                          icon: "icon-trash",
                                          color: "danger",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "vs-list",
                                [
                                  _c("vs-list-item", {
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-mail",
                                      title: tr.email,
                                    },
                                  }),
                                  _c("vs-list-item", {
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-globe",
                                      title: tr.website,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Email")]),
              _c("vs-th", [_vm._v("Name")]),
              _c("vs-th", [_vm._v("Website")]),
              _c("vs-th", [_vm._v("Nro")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-table :data="users">\n\n    <template slot="thead">\n      <vs-th>Email</vs-th>\n      <vs-th>Name</vs-th>\n      <vs-th>Website</vs-th>\n      <vs-th>Nro</vs-th>\n    </template>\n\n    <template slot-scope="{data}">\n      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">\n        <vs-td :data="tr.email">\n          ' +
            _vm._s("{{ tr.email }}") +
            '\n        </vs-td>\n        <vs-td :data="tr.username">\n          ' +
            _vm._s("{{ tr.name }}") +
            '\n        </vs-td>\n        <vs-td :data="tr.website">\n          ' +
            _vm._s("{{ tr.website }}") +
            '\n        </vs-td>\n        <vs-td :data="tr.id">\n          ' +
            _vm._s("{{ tr.id }}") +
            '\n        </vs-td>\n\n          <template class="expand-user" slot="expand">\n            <div class="con-expand-users w-full">\n              <div class="con-btns-user flex items-center justify-between">\n                <div class="con-userx flex items-center justify-start">\n                  <vs-avatar :badge="tr.id" size="45px" :src="`https://randomuser.me/api/portraits/women/${indextr}.jpg`" />\n                  <span>' +
            _vm._s("{{ tr.name }}") +
            '</span>\n                </div>\n                <div class="flex">\n                  <vs-button type="border" size="small" icon-pack="feather" icon="icon-phone" class="mr-2"></vs-button>\n                  <vs-button type="border" size="small" icon-pack="feather" icon="icon-send" color="success" class="mr-2"></vs-button>\n                  <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button>\n                </div>\n              </div>\n              <vs-list>\n                <vs-list-item icon-pack="feather" icon="icon-mail" :title="tr.email"></vs-list-item>\n                <vs-list-item icon-pack="feather" icon="icon-globe" :title="tr.website"></vs-list-item>\n              </vs-list>\n            </div>\n          </template>\n      </vs-tr>\n    </template>\n  </vs-table>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      users: [.....]\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }