var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Multiple Selected", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To select multiples "),
        _c("strong", [_vm._v("tr")]),
        _vm._v(" we have the property "),
        _c("code", [_vm._v("multiple")]),
        _vm._v(" and each of the selected items will be added to the "),
        _c("code", [_vm._v("v-model")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "if you need to execute a certain function to select the user, we have the property "
            ),
            _c("code", [_vm._v("@selected")]),
            _vm._v(", as the first parameter the data is returned"),
          ]),
        ]
      ),
      _c("br"),
      _c(
        "vs-table",
        {
          attrs: { multiple: "", data: _vm.users },
          on: { selected: _vm.handleSelected },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr, attrs: { data: tr } },
                    [
                      _c("vs-td", { attrs: { data: data[indextr].email } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].email) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].username } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].username) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].website } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].website) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].id) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Email")]),
              _c("vs-th", [_vm._v("Name")]),
              _c("vs-th", [_vm._v("Website")]),
              _c("vs-th", [_vm._v("Nro")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("br"),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v("\n" + _vm._s(_vm.selected) + "\n        "),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <vs-table multiple v-model="selected" @selected="handleSelected" :data="users">\n\n      <template slot="thead">\n        <vs-th>Email</vs-th>\n        <vs-th>Name</vs-th>\n        <vs-th>Website</vs-th>\n        <vs-th>Nro</vs-th>\n      </template>\n\n      <template slot-scope="{data}">\n        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">\n\n          <vs-td :data="data[indextr].email">\n            ' +
            _vm._s("{{ data[indextr].email }}") +
            '\n          </vs-td>\n\n          <vs-td :data="data[indextr].username">\n            ' +
            _vm._s("{{ data[indextr].username }}") +
            '\n          </vs-td>\n\n          <vs-td :data="data[indextr].website">\n            ' +
            _vm._s("{{ data[indextr].website }}") +
            '\n          </vs-td>\n\n          <vs-td :data="data[indextr].id">\n            ' +
            _vm._s("{{ data[indextr].id }}") +
            "\n          </vs-td>\n\n        </vs-tr>\n      </template>\n    </vs-table>\n\n    <br>\n\n    <pre>" +
            _vm._s("{{ selected }}") +
            "</pre>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      selected: [],\n      users: [.....],\n    }\n  },\n  methods: {\n    handleSelected(tr) {\n      this.$vs.notify({\n        title: `Selected ${tr.username}`,\n        text: `Email: ${tr.email}`\n      })\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }