var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "table-demo" } },
    [
      _c("table-default"),
      _c("table-striped"),
      _c("table-state"),
      _c("table-pagination"),
      _c("table-single-selected"),
      _c("table-multiple-selected"),
      _c("table-expandable-data"),
      _c("table-edit-data-table"),
      _c("table-filter-sorter"),
      _c("table-miscellaneous"),
      _c("table-ssr"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }