var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Filter and Sorter", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can add the Filter functionality by adding the "),
        _c("code", [_vm._v("search")]),
        _vm._v(" property."),
      ]),
      _c("p", [
        _vm._v("if you need a property to be sorted you just have to add the "),
        _c("code", [_vm._v("sort-key")]),
        _vm._v(" property and the value you need to be sorted."),
      ]),
      _c(
        "vs-table",
        {
          attrs: { search: "", data: _vm.users },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      _c("vs-td", { attrs: { data: data[indextr].email } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].email) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].username } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].username) +
                            "\n                    "
                        ),
                      ]),
                      _c("vs-td", { attrs: { data: data[indextr].id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(data[indextr].id) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "email" } }, [
                _vm._v("Email"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "username" } }, [
                _vm._v("Name"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "id" } }, [_vm._v("Nro")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-table search :data="users">\n\n  <template slot="thead">\n    <vs-th sort-key="email">Email</vs-th>\n    <vs-th sort-key="username">Name</vs-th>\n    <vs-th sort-key="id">Nro</vs-th>\n  </template>\n\n  <template slot-scope="{data}">\n    <vs-tr :key="indextr" v-for="(tr, indextr) in data">\n\n      <vs-td :data="data[indextr].email">\n        ' +
            _vm._s("{{ data[indextr].email }}") +
            '\n      </vs-td>\n\n      <vs-td :data="data[indextr].username">\n        ' +
            _vm._s("{{ data[indextr].username }}") +
            '\n      </vs-td>\n\n      <vs-td :data="data[indextr].id">\n        ' +
            _vm._s("{{ data[indextr].id }}") +
            "\n      </vs-td>\n    </vs-tr>\n  </template>\n</vs-table>\n\n<script>\nexport default {\n  data() {\n    return {\n      users: [......]\n    }\n  },\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }